export default {
	isAuthenticated: (state) => (state.token ? true : false),
	getAuthenticatedUser: (state) => {
		return {
			nome: state.nome,
			email: state.email,
		};
	},
	getMenusPerfil: (state) => state.menusPerfil,
};
