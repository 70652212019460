export default ({ instance }) => {
	const apiCalls = {
		cadastrarUsuarioPortal(usuarioPortal) {
			return instance({
				method: "post",
				url: "usuario-portal",
				data: usuarioPortal,
			});
		},
		listarUsuariosPortal(pesquisa) {
			return instance({
				method: "get",
				url: "usuario-portal",
				params: { pesquisa },
			});
		},
		alterarUsuarioPortal(usuarioPortal) {
			return instance({
				method: "put",
				url: "usuario-portal",
				data: usuarioPortal,
			});
		},
		alterarUsuarioPortalStatus(usuarioPortalStatus) {
			return instance({
				method: "put",
				url: "usuario-portal/alterar-status",
				data: usuarioPortalStatus,
			});
		},
	};
	return apiCalls;
};
