import axios from "axios";
import store from "@/store";
import autenticacao from "./services/autenticacao";
import menu from "./services/menu";
import perfil from "./services/perfil";
import usuarioPortal from "./services/usuarioPortal";
import cliente from "./services/cliente";
import mensagem from "./services/mensagem";
import usuarioAnalytics from "./services/usuarioAnalytics";
import itemPesquisa from "./services/itemPesquisa";
import pacote from "./services/pacote";
import template from "./services/template";
import filtro from "./services/filtro";
import licenca from "./services/licenca";
import uf from "./services/uf";
import dashboard from "./services/dashboard";

const instance = axios.create({
	baseURL: process.env.VUE_APP_KURIER_ANALYTICS_CONTROLE_API,
});

instance.defaults.timeout = 1200000

instance.defaults.headers.post["Content-Type"] = "json";
instance.defaults.headers.put["Content-Type"] = "json";
instance.defaults.responseType = "json";
instance.interceptors.request.use((config) => {
	if (store.state.autenticacao.token)
		config.headers[
			"Authorization"
		] = `Bearer ${store.state.autenticacao.token}`;

	return config;
});

export default {
	...autenticacao({ instance }),
	...menu({ instance }),
	...perfil({ instance }),
	...usuarioPortal({ instance }),
	...cliente({ instance }),
	...mensagem({instance}),
	...usuarioAnalytics({ instance }),
	...itemPesquisa({ instance }),
	...pacote({ instance }),
	...template({ instance }),
	...filtro({ instance }),
	...licenca({ instance }),
	...uf({ instance }),
	...dashboard({ instance }),
};
