export default ({ instance }) => {
	const apiCalls = {
		cadastrarPacote(pacote) {
			return instance({
				method: "post",
				url: "pacote",
				data: pacote,
			});
		},
		listarPacotes(pesquisa) {
			return instance({
				method: "get",
				url: "pacote",
				params: { pesquisa },
			});
		},
		alterarPacote(pacote) {
			return instance({
				method: "put",
				url: "pacote",
				data: pacote,
			});
		},
		alterarPacoteStatus(pacote) {
			return instance({
				method: "put",
				url: "pacote/alterar-status",
				data: pacote,
			});
		},
	};
	return apiCalls;
};
