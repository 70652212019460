<template>
	<div id="app">
		<a-config-provider :locale="pt_BR">
			<router-view />
		</a-config-provider>
	</div>
</template>

<script>
import pt_BR from "ant-design-vue/lib/locale-provider/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

export default {
	name: "App",
	data() {
		return {
			pt_BR,
		};
	},
};
</script>

<style>
#app {
	font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	color: #2c3e50;
	/* height: 100%; */
}

.ant-table-small {
    border: 1px solid #e8e8e8 !important;
    border-radius: 0px !important;
}

.ant-table-middle {
    border: 1px solid #e8e8e8 !important;
    border-radius: 0px !important;
}

.ant-table-small > .ant-table-content .ant-table-header {
    background-color: #b1b7bd !important;
}

.ant-table-middle > .ant-table-content .ant-table-header {
    background-color: #b1b7bd !important;
}

.ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0 0px !important;
}

.ant-table-middle > .ant-table-content > .ant-table-body {
    margin: 0 0px !important;
}
</style>