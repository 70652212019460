export default ({ instance }) => {
	const apiCalls = {
		cadastrarPerfil(perfil) {
			return instance({
				method: "post",
				url: "perfil",
				data: perfil,
			});
		},
		listarPerfis(pesquisa) {
			return instance({
				method: "get",
				url: "perfil",
				params: { pesquisa },
			});
		},
		alterarPerfil(perfil) {
			return instance({
				method: "put",
				url: "perfil",
				data: perfil,
			});
		},
		alterarPerfilStatus(perfilStatus) {
			return instance({
				method: "put",
				url: "perfil/alterar-status",
				data: perfilStatus,
			});
		},
	};
	return apiCalls;
};
