export default ({ instance }) => {
	const apiCalls = {
		cadastrarUfs(uf) {
			return instance({
				method: "post",
				url: "uf",
				data: uf,
			});
		},
		listarUfs(pesquisa) {
			return instance({
				method: "get",
				url: "uf",
				params: { pesquisa },
			});
		},
		alterarUfs(uf) {
			return instance({
				method: "put",
				url: "uf",
				data: uf,
			});
		},

	};
	return apiCalls;
};
