export default ({ instance }) => {
	const apiCalls = {
		cadastrarTemplate(template) {
			return instance({
				method: "post",
				url: "template",
				data: template,
			});
		},
		listarTemplates(pesquisa) {
			return instance({
				method: "get",
				url: "template",
				params: { pesquisa },
			});
		},
		alterarTemplate(template) {
			return instance({
				method: "put",
				url: "template",
				data: template,
			});
		},
		alterarTemplateStatus(template) {
			return instance({
				method: "put",
				url: "template/alterar-status",
				data: template,
			});
		},
	};
	return apiCalls;
};
