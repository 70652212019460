export default {
	id: null,
	nome: null,
	login: null,
	email: null,
	idPerfil: null,
	token: null,
	menusPerfil: [],
	openKeys: [],
    selectedKeys: []
};
