export default ({ instance }) => {
	const apiCalls = {
		cadastrarMensagem(mensagem) {
			return instance({
				method: "post",
				url: "mensagem",
				data: mensagem,
			});
		},
		listarMensagem(pesquisa) {
			return instance({
				method: "get",
				url: "mensagem",
				params: { pesquisa },
			});
		},
		alterarMensagem(mensagem) {
			return instance({
				method: "put",
				url: "mensagem",
				data: mensagem,
			});
		},
		alterarMensagemStatus(mensagemStatus) {
			return instance({
				method: "put",
				url: "mensagem/alterar-status",
				data: mensagemStatus,
			});
		},
		historicoMensagens(idMsg) {
			return instance({
				method: "get",
				url: `mensagem/relatorio-retorno/?id=${idMsg}`,
				//data: mensagemStatus,
			});
		},
		exportarMensagens(lista) {
			return instance({
				method: "post",
				url: "mensagem/relatorio-exportar",
				responseType: 'arraybuffer',
				data: lista,
			});
		},
	};
	return apiCalls;
};
