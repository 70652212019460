import Vue from "vue";
import Antd from "ant-design-vue";
import PortalVue from "portal-vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import { DO_LOGOUT } from "@/store/modules/autenticacao/types";
import "@/assets/styles/theme.less";

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(Antd);

Vue.prototype.$logout = () => {
	store.dispatch(DO_LOGOUT);

	router.replace({ name: "login" });

	return;
};

new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");
