import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		alias: ["/"],
		name: "login",
		component: () => import("@/views/LoginView"),
		meta: {
			icon: "login",
			description: "Login",
			requiresAuth: false,
			grantAll: true,
		},
	},
	{
		path: "/home",
		name: "home",
		component: () => import("@/views/HomeView.vue"),
		meta: { description: "Home", requiresAuth: true, grantAll: false },
		redirect: { name: "dashboard" },
		children: [
			{
				path: "/dashboard",
				name: "dashboard",
				component: () => import("@/views/DashboardView.vue"),
				meta: {
					description: "Dashboard",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/gerenciamentos-interno-perfis",
				name: "gerenciamentosInternoPerfis",
				component: () =>
					import("@/views/gerenciamentos/interno/PerfilView.vue"),
				meta: {
					description: "Gerenciamento Interno dos Perfis",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/gerenciamentos-interno-usuarios-portal",
				name: "gerenciamentosInternoUsuariosPortal",
				component: () =>
					import(
						"@/views/gerenciamentos/interno/UsuarioPortalView.vue"
					),
				meta: {
					description: "Gerenciamento Interno dos Usuários do Portal",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/gerenciamentos-externo-clientes",
				name: "gerenciamentosExternoClientes",
				component: () =>
					import("@/views/gerenciamentos/externo/ClienteView.vue"),
				meta: {
					description: "Gerenciamento Externo dos Clientes",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/gerenciamentos-externo-usuarios-analytics",
				name: "gerenciamentosExternoUsuariosAnalytics",
				component: () =>
					import(
						"@/views/gerenciamentos/externo/UsuarioAnalyticsView.vue"
					),
				meta: {
					description:
						"Gerenciamento Externo dos Usuários do Analytics",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/administracao-itens-pesquisa",
				name: "administracaoItensPesquisa",
				component: () =>
					import("@/views/administracao/ItemPesquisaView.vue"),
				meta: {
					description: "Administração dos Itens de Pesquisa",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/administracao-pacotes",
				name: "administracaoPacotes",
				component: () => import("@/views/administracao/PacoteView.vue"),
				meta: {
					description: "Administração dos Pacotes",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/administracao-templates",
				name: "administracaoTemplates",
				component: () =>
					import("@/views/administracao/TemplateView.vue"),
				meta: {
					description: "Administração dos Templates",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/administracao-filtros",
				name: "administracaoFiltros",
				component: () => import("@/views/administracao/FiltroView.vue"),
				meta: {
					description: "Administração dos Filtros",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/administracao-mensagem",
				name: "administracaoMensagem",
				component: () => import("@/views/administracao/MensagensView.vue"),
				meta: {
					description: "Administração de Mensagens",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/relatorio-acessos",
				name: "administracaoAcessos",
				component: () => import("@/views/relatorio/RelatorioAcessos.vue"),
				meta: {
					description: "Relatório de Acessos ao Analytics",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/administracao-licencas",
				name: "administracaoLicencas",
				component: () =>
					import("@/views/administracao/LicencaView.vue"),
				meta: {
					description: "Administração dos Licenças",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/historico-pesquisa",
				name: "historicopesquisa",
				component: () =>
					import("@/views/relatorio/HistoricoPesquisa.vue"),
				meta: {
					description: "Histórico de Pesquisas Descritivo",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/historico-pesquisa-preditivo",
				name: "historicopesquisapreditivo",
				component: () =>
					import("@/views/relatorio/HistoricoPesquisaPreditivo.vue"),
				meta: {
					description: "Histórico de Pesquisas Preditivo",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/historico-pesquisa-trabalhista",
				name: "historico-pesquisa-trabalhista",
				component: () =>
					import("@/views/relatorio/HistoricoPesquisaTrabalhista.vue"),
				meta: {
					description: "Histórico de Pesquisas Trabalhista",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/relatorio-consulta",
				name: "relatorio-consulta",
				component: () =>
					import("@/views/relatorio/RelatorioConsulta.vue"),
				meta: {
					description: "Relatório de pesquisas do Analytics",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/relatorio-resumo-consulta",
				name: "relatorio-resumo-consulta",
				component: () =>
					import("@/views/relatorio/RelatorioResumoConsulta.vue"),
				meta: {
					description: "Relatório detalhado de pesquisas do Analytics",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/relatorio-historico-mensagem",
				name: "relatorio-historico-mensagem",
				component: () =>
					import("@/views/relatorio/RelatorioMensagens.vue"),
				meta: {
					description: "Relatório Histórico Mensagens",
					requiresAuth: true,
					grantAll: false,
				},
			},
			{
				path: "/relatorio-consulta-macro",
				name: "relatorio-consulta-macro",
				component: () =>
					import("@/views/relatorio/RelatorioResumoConsultaMacro.vue"),
				meta: {
					description: "Relatório de pesquisas do Analytics",
					requiresAuth: true,
					grantAll: false,
				},
			},
		],
	},
	{
		path: "*",
		component: () => import("@/views/NotFoundView.vue"),
		meta: {
			requiresAuth: false,
			grantAll: false,
		},
	},
];

const router = new VueRouter({
	// mode: "history",
	// base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (
		to.matched.some((record) => record.meta.requiresAuth) &&
		to.matched.some((record) => !record.meta.grantAll) &&
		!store.getters.isAuthenticated
	) {
		next({
			name: "login",
		});
	} else {
		next();
	}
});

export default router;
