import API from "@/api";
import defaultState from "./state";
import { DO_LOGIN, DO_LOGOUT, SET_AUTH } from "./types";

export default {
	[DO_LOGIN]({ commit }, payload) {
		return new Promise((resolve, reject) => {
			API.login(payload)
				.then((res) => {
					commit(SET_AUTH, res.data.result);

					resolve();
				})
				.catch((error) => {
					if (error.response) {
						reject({
							message: error.response.data
								? error.response.data.message
								: error.message,
						});
					} else {
						reject({
							message: "Algum problema ocorreu, tente novamente.",
						});
					}
				});
		});
	},
	[DO_LOGOUT]({ commit }) {
		return new Promise((resolve, reject) => {
			commit(SET_AUTH, {
				...defaultState,
			});
		});
	},
};
