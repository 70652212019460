import defaultState from "./state";
import { SET_AUTH, SET_LOGOUT, SET_OPENKEYS, SET_SELECTEDKEYS } from "./types";

export default {
	[SET_AUTH](state, payload) {
		state.id = payload.id;
		state.nome = payload.nome;
		state.login = payload.login;
		state.email = payload.email;
		state.idPerfil = payload.idPerfil;
		state.token = payload.token;
		state.menusPerfil = payload.menusPerfil;
	},
	[SET_LOGOUT](state, payload) {
		state = { ...defaultState };
	},
	[SET_OPENKEYS](state, payload) {
		state.openKeys = payload;
	},
	[SET_SELECTEDKEYS](state, payload) {
		state.selectedKeys = payload;
	},
};
