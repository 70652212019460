export default ({ instance }) => {
	const apiCalls = {
		cadastrarFiltro(filtro) {
			return instance({
				method: "post",
				url: "filtro",
				data: filtro,
			});
		},
		listarFiltros(pesquisa) {
			return instance({
				method: "get",
				url: "filtro",
				params: { pesquisa },
			});
		},
		listarFiltrosUF() {
			return instance({
				method: "get",
				url: "filtro/filtro-uf",
			});
		},
		alterarFiltro(filtro) {
			return instance({
				method: "put",
				url: "filtro",
				data: filtro,
			});
		},
		alterarFiltroStatus(filtroStatus) {
			return instance({
				method: "put",
				url: "filtro/alterar-status",
				data: filtroStatus,
			});
		},
	};
	return apiCalls;
};
