export default ({ instance }) => {
	const apiCalls = {
		dashBoardConsultaAnalytics(filtro) {
			return instance({
				method: "post",
				url: "dashboard/dashboarconsulta",
				data: filtro,
			});
		},
	};
	return apiCalls;
};
