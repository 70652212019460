import Vuex from "vuex";
import Vue from "vue";
import autenticacao from "@/store/modules/autenticacao";
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.sessionStorage
  })

export default new Vuex.Store({
	plugins: [vuexLocal.plugin],
	modules: { autenticacao },
	strict: process.env.NODE_ENV !== "production",
});
