export default ({ instance }) => {
	const apiCalls = {
		cadastrarLicenca(licenca) {
			return instance({
				method: "post",
				url: "licenca",
				data: licenca,
			});
		},
		listarLicencas(pesquisa) {
			return instance({
				method: "get",
				url: "licenca",
				params: { pesquisa },
			});
		},
		listarLicencasUsuario(IdCliente) {
			return instance({
				method: "get",
				url: "licenca/licencaUsuario",
				params: { IdCliente },
			});
		},
		historicoLicencaPorCliente(idCliente) {
			return instance({
				method: "get",
				url: "licenca/historico",
				params: { idCliente },
			});
		},
		listarLicencasitensPesquisa(idLicenca) {
			return instance({
				method: "get",
				url: "licenca/itensPesquisa",
				params: { idLicenca },
			});
		},
		alterarLicenca(licenca) {
			return instance({
				method: "put",
				url: "licenca",
				data: licenca,
			});
		},
		alterarLicencaStatus(licencaStatus) {
			return instance({
				method: "put",
				url: "licenca/alterar-status",
				data: licencaStatus,
			});
		},
	};
	return apiCalls;
};
