export default ({ instance }) => {
	const apiCalls = {
		cadastrarUsuarioAnalytics(usuarioAnalytics) {
			return instance({
				method: "post",
				url: "usuario-analytics",
				data: usuarioAnalytics,
			});
		},
		listarHistoricoUsuarioAnalytics(IdUsuarioAnalytics) {
			return instance({
				method: "get",
				url: "usuario-analytics/historico",
				params: { IdUsuarioAnalytics },
			});
		},
		listarUsuariosAnalytics(pesquisa) {
			return instance({
				method: "get",
				url: "usuario-analytics",
				params: { pesquisa },
			});
		},
		listarUsuarioPorCliente(IdUsuarioAnalytics) {
			return instance({
				method: "get",
				url: "usuario-analytics/usuario-por-cliente",
				params: { IdUsuarioAnalytics },
			});
		},
		alterarUsuarioAnalytics(usuarioAnalytics) {
			return instance({
				method: "put",
				url: "usuario-analytics",
				data: usuarioAnalytics,
			});
		},
		alterarUsuarioAnalyticsStatus(usuarioAnalyticsStatus) {
			return instance({
				method: "put",
				url: "usuario-analytics/alterar-status",
				data: usuarioAnalyticsStatus,
			});
		},
		listarHistPesquisaAnalytics(filtro) {
			return instance({
				method: "post",
				url: "usuario-analytics/searchterm",
				data: filtro,
			});
		},
		listarHistPesquisaTrabalhista(filtro) {
			return instance({
				method: "post",
				url: "usuario-analytics/historico-pesquisa-trabalhista",
				data: filtro,
			});
		},
		listarHistPesquisaPreditivo(filtro) {
			return instance({
				method: "post",
				url: "usuario-analytics/historicopesquisapreditivo",
				data: filtro,
			});
		},
		exportHistoricoConsultaAnalyticsExcel(dados) {
			return instance({
				method: "post",
				url:
					"usuario-analytics/exportconsultaporusuario",
				responseType: "blob",
				data:dados,
				headers: { "x-Requested-with": "XMLHttpRequest" },
			});
		},
		exportHistoricoConsultaTrabalhistaAnalyticsExcel(dados) {
			debugger
			return instance({
				method: "post",
				url:"usuario-analytics/exportar-pesquisa-trabalhista",
				responseType: "blob",
				headers: { "x-Requested-with": "XMLHttpRequest" },
				data: dados,
			});
		},
		exportHistoricoConsultaPreditivoExcel(dados) {
			return instance({
				method: "post",
				url:
					"usuario-analytics/exportconsultapreditivoporusuario",
				responseType: "blob",
				data: dados,
				headers: { "x-Requested-with": "XMLHttpRequest" },
			});
		},
		listarRelatConsultAnalytics(filtro) {
			return instance({
				method: "post",
				url: "usuario-analytics/relatorioconsulta",
				data: filtro,
			});
		},
		listarRelatConsultMacroAnalytics(filtro) {
			return instance({
				method: "post",
				url: "usuario-analytics/relatorioconsultamacro",
				data: filtro,
			});
		},
		relatorioAcessoAnalytics(filtro) {
			return instance({
				method: "post",
				url:"usuario-analytics/historicoacesso",
				data : filtro,
				headers: { "x-Requested-with": "XMLHttpRequest" },
			});
		},
		exportRelatConsultAnalytic(dadosExportar, exibirUsuario) {
			debugger;
			return instance({
				method: "post",
				url:
					"usuario-analytics/relatorioconsulta-exportar?exibirUsuario=" +
					exibirUsuario,
				responseType: "arraybuffer",
				headers: { "x-Requested-with": "XMLHttpRequest" },
				data: dadosExportar,
			});
		},
		exportRelatConsultAnalyticResumo(dadosExportar, exibirUsuario) {
			debugger;
			return instance({
				method: "post",
				url: `usuario-analytics/relatorioresumoconsulta-exportar?exibirUsuario=${exibirUsuario}`,
				responseType: "arraybuffer",
				headers: { "x-Requested-with": "XMLHttpRequest" },
				data: dadosExportar,
			});
		},
		exportRelatConsultAnalyticsMacro(dadosExportar) {
			debugger;
			return instance({
				method: "post",
				url: `usuario-analytics/relatorioconsulta-macro-exportar`,
				responseType: "arraybuffer",
				headers: { "x-Requested-with": "XMLHttpRequest" },
				data: dadosExportar,
			});
		},		
		exportRelatorioAcessoAnalytics(filtro) {
			return instance({
				method: "post",
				url:
					"usuario-analytics/exportar-historicoacesso",
				responseType: "arraybuffer",
				headers: { "x-Requested-with": "XMLHttpRequest" },
				data: filtro,
			});
		},		
	};
	return apiCalls;
};
