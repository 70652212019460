export default ({ instance }) => {
	const apiCalls = {
		cadastrarItemPesquisa(itemPesquisa) {
			return instance({
				method: "post",
				url: "item-pesquisa",
				data: itemPesquisa,
			});
		},
		listarItensPesquisa(pesquisa) {
			return instance({
				method: "get",
				url: "item-pesquisa",
				params: { pesquisa },
			});
		},
		listarItensPesquisaFiltro(pesquisa) {
			return instance({
				method: "get",
				url: "item-filtro",
				params: { pesquisa },
			});
		},
		alterarItemPesquisa(itemPesquisa) {
			return instance({
				method: "put",
				url: "item-pesquisa",
				data: itemPesquisa,
			});
		},
		alterarItemPesquisaStatus(itemPesquisaStatus) {
			return instance({
				method: "put",
				url: "item-pesquisa/alterar-status",
				data: itemPesquisaStatus,
			});
		},
	};
	return apiCalls;
};
