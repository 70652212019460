export default ({ instance }) => {
	const apiCalls = {
		listarMenus(id) {
			return instance({
				method: "get",
				url: "menu",
				params: { id },
			});
		},
		pesquisarMenusPerfil(idPerfil) {
			return instance({
				method: "get",
				url: "menu/pesquisar-menus-perfil",
				params: { idPerfil },
			});
		},
		cadastrarMenusPerfil(menusPerfil) {
			return instance({
				method: "post",
				url: "menu/menus-perfil",
				data: menusPerfil,
			});
		},
	};
	return apiCalls;
};
