export default ({ instance }) => {
	const apiCalls = {
		cadastrarCliente(cliente) {
			return instance({
				method: "post",
				url: "cliente",
				data: cliente,
			});
		},
		listarClientes(pesquisa) {
			return instance({
				method: "get",
				url: "cliente",
				params: { pesquisa },
			});
		},
		listarHistorico(IdCliente) {
			return instance({
				method: "get",
				url: "cliente/historico",
				params: { IdCliente },
			});
		},
		listarClientesSemUsuario() {
			return instance({
				method: "get",
				url: "cliente/cliente-sem-usuario",
			});
		},
		alterarCliente(cliente) {
			return instance({
				method: "put",
				url: "cliente",
				data: cliente,
			});
		},
		alterarClienteStatus(clienteStatus) {
			return instance({
				method: "put",
				url: "cliente/alterar-status",
				data: clienteStatus,
			});
		},
	};
	return apiCalls;
};
