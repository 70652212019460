export default ({ instance }) => {
	const apiCalls = {
		login(usuario) {
			return instance({
				method: "post",
				url: "autenticacao",
				data: usuario,
			});
		},
	};

	return apiCalls;
};
